export default class Toggle {
    constructor (selecteur, options = {}) {
        this.selecteur = selecteur;
        this.options = Object.assign({
            onChange: () => {

            }
        }, options);
        this.toggle()
        this.valueIsTrue()
    }

    toggle() {
        const input = this.selecteur.children[0];
        const label = this.selecteur.children[1].firstElementChild && this.selecteur.children[1].firstElementChild.classList.contains('toggle__label') ? this.selecteur.children[1].firstElementChild : null;

        input.addEventListener('click', async (e) => {

            e.stopPropagation();
            e.preventDefault();

            const event = e;
            let checked = event.target.checked;


            if (this.options.onBefore) {
                const prevent = await this.options.onBefore(event);
                if (prevent !== undefined && !prevent) {
                    return false;
                }
            }

            setTimeout(() => {
                input.checked = checked;
                if (label) {
                    label.innerHTML = checked ? 'Oui' : 'Non';
                }
                if (this.options.onChange) {
                    this.options.onChange(event);
                }
            }, 0);

        });
    }

    valueIsTrue() {
        const input = this.selecteur.children[0];
        const label = this.selecteur.children[1].firstElementChild && this.selecteur.children[1].firstElementChild.classList.contains('toggle__label') ? this.selecteur.children[1].firstElementChild : null;
        const checked = this.selecteur.children[0].checked;

        input.checked = checked;
        if (label) {
            label.innerHTML = checked ? 'Oui' : 'Non';
        }

    }
}
